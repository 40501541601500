import { BreakPointValues } from "../../breakPoints";

export enum Theme {
  DARK = "dark",
  LIGHT = "light"
}

const themeGlobals = {
  margin: "2em",
  formElementPadding: "0.5em 1em",
  font: {
    sansSerif: "'Open Sans', sans-serif",
    serif: "'Roboto Slab', serif",
    contrast: "'Wilderness', serif",
    size: {
      s: "0.85em",
      m: "1em",
      l: "1.5em",
      xl: "2em"
    }
  }
};

type GlobalsType = typeof themeGlobals;

export type ThemeType = {
  background: string;
  textColor: string;
  mainColor: string;
  accentColor: string;
  gradient: string;
  linkColor: string;
  navLinkColor: string;
  navLinkHover: string;
} & GlobalsType;

type ThemesTypes = { [key in Theme]: ThemeType };

export const colorPalette = {
  primary0: "#21ACE1",
  primary1: "#B7DCEA",
  primary2: "#5AB9DD",
  primary3: "#02A7E6",
  primary4: "#01678E",

  secondary10: "#334FE6",
  secondary11: "#BEC6FE",
  secondary12: "#687CE2",
  secondary13: "#072BEA",
  secondary14: "#031B9B",

  secondary20: "#1AE967",
  secondary21: "#B9F0CD",
  secondary22: "#58E68D",
  secondary23: "#00EC58",
  secondary24: "#00A43D",

  gray0: "#3F3E40",
  gray1: "#7F7D81",
  gray2: "#5F5D60",
  gray3: "#181819",
  gray4: "#0C0A0E"
};

export const themes: ThemesTypes = {
  [Theme.LIGHT]: {
    background: "white",
    textColor: colorPalette.gray0,
    mainColor: colorPalette.primary0,
    accentColor: colorPalette.secondary10,
    gradient: `linear-gradient(104.78deg, ${colorPalette.primary0} 0%, ${colorPalette.secondary10} 102.21%)`,
    linkColor: colorPalette.primary4,
    navLinkColor: colorPalette.gray0,
    navLinkHover: colorPalette.secondary10,
    ...themeGlobals
  },
  [Theme.DARK]: {
    background: colorPalette.gray3,
    textColor: "white",
    mainColor: colorPalette.primary0,
    accentColor: colorPalette.secondary20,
    gradient: `linear-gradient(104.78deg, ${colorPalette.primary0} 0%, ${colorPalette.secondary20} 102.21%)`,
    linkColor: colorPalette.secondary21,
    navLinkColor: "white",
    navLinkHover: colorPalette.primary0,
    ...themeGlobals
  }
};

export type ScTheme = ThemeType & {
  breakPoints: BreakPointValues;
};
