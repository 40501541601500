import React from "react";
import { ThemeProvider } from "./src/components/layout/themeContext";
import { Theme } from "./src/components/layout/themeContext/themes";
import { BreakpointProvider } from "./src/components/breakPoints";

// Wraps all gatsby content in ThemeProvider. This provider component doesnt change on navigating sites
export const wrapRootElement = ({ element }) => (
  <BreakpointProvider>
    <ThemeProvider initialTheme={Theme.LIGHT}>{element}</ThemeProvider>
  </BreakpointProvider>
);
