import React, { FunctionComponent, ReactElement, useContext, useEffect, useState } from "react";
import { ThemeProvider as StyledComponentsContextProvider } from "styled-components";
import { ScTheme, Theme, themes } from "./themes";
import { useBreakPoints } from "../../breakPoints";

const LS_THEME = "theme";

type ThemeContextType = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
};
const themeContextDefaultValue: ThemeContextType = {
  theme: Theme.LIGHT,
  setTheme: () => undefined
};

const ThemeContext = React.createContext<ThemeContextType>(themeContextDefaultValue);

const supportsDarkMode = (): boolean => window.matchMedia("(prefers-color-scheme: dark)").matches;

const getTheme = (defaulTheme: Theme): Theme => {
  const localStorageTheme = localStorage.getItem(LS_THEME);

  if (localStorageTheme) {
    return localStorageTheme as Theme;
  } else if (supportsDarkMode()) {
    return Theme.DARK;
  } else {
    return defaulTheme;
  }
};

type ThemeProviderProps = {
  initialTheme: Theme;
};

const flipTheme = (theme: Theme): Theme => (theme === Theme.DARK ? Theme.LIGHT : Theme.DARK);

const ThemeProvider: FunctionComponent<ThemeProviderProps> = ({ children, initialTheme }) => {
  const [currentTheme, setCurrentTheme] = useState<Theme>(initialTheme);

  const breakPoints = useBreakPoints();

  useEffect(() => setCurrentTheme(getTheme(initialTheme)), []);

  const setTheme = (theme: Theme): void => {
    localStorage.setItem(LS_THEME, theme);
    setCurrentTheme(theme);
  };

  const styledComponentsTheme: ScTheme = { ...themes[currentTheme], breakPoints };

  return (
    <ThemeContext.Provider value={{ theme: currentTheme, setTheme }}>
      <StyledComponentsContextProvider theme={styledComponentsTheme}>
        {children as ReactElement}
      </StyledComponentsContextProvider>
    </ThemeContext.Provider>
  );
};

const useTheme = (): ThemeContextType => useContext(ThemeContext);

export { ThemeProvider, useTheme, flipTheme };

export default ThemeContext;
