import React, { createContext, FC, useContext, useEffect, useState } from "react";

enum BreakPoint {
  xs = "xs",
  md = "md",
  lg = "lg"
}

export type Queries = {
  [key in BreakPoint]: string;
};

export type BreakPointValues = {
  [key in BreakPoint]: boolean;
};

type MediaQueryLists = {
  [key in BreakPoint]?: MediaQueryList;
};

export const maxWidthXs = 600;
export const maxWidthMd = 1023;
export const minWidthLg = 1024;

const queries: Queries = {
  xs: `(max-width: ${maxWidthXs}px)`,
  md: `(max-width: ${maxWidthMd}px)`,
  lg: `(min-width: ${minWidthLg}px)`
};

const defaultBreakpointsValueState: BreakPointValues = {
  [BreakPoint.xs]: false,
  [BreakPoint.md]: false,
  [BreakPoint.lg]: true
};

const BreakpointContext = createContext<BreakPointValues>(defaultBreakpointsValueState);

const BreakpointProvider: FC = ({ children }) => {
  const [queryMatch, setQueryMatch] = useState<BreakPointValues>(defaultBreakpointsValueState);

  useEffect(() => {
    const mediaQueryLists: MediaQueryLists = {};
    const breakPoints = Object.keys(BreakPoint) as BreakPoint[];
    let isEventListenerAttachedToDom = false;

    const handleQueryListener = (): void => {
      const updatedMatches = breakPoints.reduce((result, breakPoint) => {
        result[breakPoint] = Boolean(mediaQueryLists[breakPoint] && mediaQueryLists[breakPoint]?.matches);
        return result;
      }, {} as BreakPointValues);
      setQueryMatch(updatedMatches);
    };

    if (window && window.matchMedia) {
      const matches: BreakPointValues = {} as BreakPointValues;
      breakPoints.forEach(breakPoint => {
        if (typeof queries[breakPoint] === "string") {
          mediaQueryLists[breakPoint] = window.matchMedia(queries[breakPoint]);
          matches[breakPoint] = Boolean(mediaQueryLists[breakPoint]?.matches);
        } else {
          matches[breakPoint] = false;
        }
      });
      setQueryMatch(matches);
      isEventListenerAttachedToDom = true;
      breakPoints.forEach(breakPoint => {
        if (typeof queries[breakPoint] === "string") {
          mediaQueryLists[breakPoint]?.addListener(handleQueryListener);
        }
      });
    }

    return (): void => {
      if (isEventListenerAttachedToDom) {
        breakPoints.forEach(media => {
          if (typeof queries[media] === "string") {
            mediaQueryLists[media]?.removeListener(handleQueryListener);
          }
        });
      }
    };
  }, [queries]);

  return <BreakpointContext.Provider value={queryMatch}>{children}</BreakpointContext.Provider>;
};

const useBreakPoints = (): BreakPointValues => useContext(BreakpointContext);

export { BreakpointProvider, useBreakPoints };
